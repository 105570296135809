<template>
  <page>
    <template slot="headerRight">
      <el-button
        @click="groupHandler"
        :disabled="!multipleSelection.length"
        v-auth="'supplyStores:updateStoresGroup'"
        type="primary"
      >
        修改分组
      </el-button>

      <el-button
        v-auth="'supplyStores:chargeTemplate'"
        @click="importHandler"
        type="primary"
      >
        批量充值
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="门店/经销商名称" prop="storesName">
          <el-input
            v-model="params.storesName"
            placeholder="门店/经销商名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="所属地区" prop="address">
          <el-input v-model="params.address" placeholder="所属地区"></el-input>
        </el-form-item> -->
        <el-form-item label="支付类型" prop="payWayId">
          <el-select v-model="params.payWayId" clearable>
            <el-option
              v-for="item in payList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店/经销商分组" prop="groupManageId">
          <el-select clearable v-model="params.groupManageId">
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="storesNo"
          label="门店/经销商编号"
        />
        <el-table-column
          show-overflow-tooltip
          prop="storesName"
          label="门店/经销商名称"
        />
        <el-table-column
          show-overflow-tooltip
          prop="detailRegion"
          label="所属地区"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupManage"
          label="门店/经销商分组"
        />
        <el-table-column
          show-overflow-tooltip
          prop="payWayStr"
          label="支付类型"
        />
        <el-table-column
          show-overflow-tooltip
          prop="usableBalance"
          label="可用余额(元)"
        />

        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'supplyStores:updateStoresPayAndGroup'"
              @click="editHandler(row)"
              type="text"
              size="mini"
              >编辑</el-button
            >
            <template v-if="row.payWayStr.includes('充值/赊账')">
              <el-button
                v-auth="'balance:applyCharge'"
                @click="chargeHandler(row)"
                type="text"
                size="mini"
                >线下充值申请</el-button
              >
              <el-button
                v-auth="'balance:queryChargeRecord'"
                @click="amountDetail(row)"
                type="text"
                size="mini"
                >交易明细</el-button
              >
            </template>

            <!-- <template v-if="row.payWayId == '赊销'">
              <el-button @click="repaymentHandler(row)" type="text" size="mini"
                >还款</el-button
              >
              <el-button @click="creditDetail(row)" type="text" size="mini"
                >赊销详情</el-button
              >
            </template> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <EditModal
      v-if="editVisible"
      @getData="getData(true)"
      :visible.sync="editVisible"
      :current-item="currentItem"
    />

    <AmountModal
      v-if="amountVisible"
      @getData="getData(true)"
      :visible.sync="amountVisible"
      :current-item="currentItem"
    />

    <CreditModal
      v-if="creditVisible"
      @getData="getData(true)"
      :visible.sync="creditVisible"
      :current-item="currentItem"
    />

    <ChargeModal
      v-if="chargeVisible"
      @getData="getData(true)"
      :visible.sync="chargeVisible"
      :current-item="currentItem"
    />

    <RepaymentModal
      v-if="repaymentVisible"
      @getData="getData(true)"
      :visible.sync="repaymentVisible"
      :current-item="currentItem"
    />

    <GroupModal
      v-if="groupVisible"
      @getData="getData(true)"
      :visible.sync="groupVisible"
      :current-item="currentItem"
      :multipleSelection="multipleSelection"
    />

    <ImportModal
      v-if="importVisible"
      @getData="getData(true)"
      :visible.sync="importVisible"
      :current-item="currentItem"
      :multipleSelection="multipleSelection"
      :params="params"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import EditModal from './components/EditModal.vue'
import AmountModal from './components/AmountModal.vue'
import CreditModal from './components/CreditModal.vue'
import ChargeModal from './components/ChargeModal.vue'
import RepaymentModal from './components/RepaymentModal.vue'
import GroupModal from './components/GroupModal.vue'
import ImportModal from './components/ImportModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      editVisible: false,
      amountVisible: false,
      creditVisible: false,
      chargeVisible: false,
      repaymentVisible: false,
      groupVisible: false,
      importVisible: false,
      currentItem: null,
      payList: [
        {
          label: '充值/赊销',
          value: 1
        },
        {
          label: '聚合支付',
          value: 2
        }
      ],
      tableData: [],
      multipleSelection: [],
      groupList: [],
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getGroupList()
    this.getData()
  },
  components: {
    EditModal,
    AmountModal,
    CreditModal,
    ChargeModal,
    RepaymentModal,
    GroupModal,
    ImportModal
  },
  methods: {
    importHandler(row) {
      this.currentItem = row
      this.importVisible = true
    },
    groupHandler(row) {
      this.currentItem = row
      this.groupVisible = true
    },

    editHandler(row) {
      this.currentItem = row
      this.editVisible = true
    },
    chargeHandler(row) {
      this.currentItem = row
      this.chargeVisible = true
    },
    amountDetail(row) {
      this.currentItem = row
      this.amountVisible = true
    },
    repaymentHandler(row) {
      this.currentItem = row
      this.repaymentVisible = true
    },
    creditDetail(row) {
      this.currentItem = row
      this.creditVisible = true
    },
    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.status == 1 ? '停用' : '启用'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.storeInfoManage.updateMemberInfo({
            id: row.id,
            status: row.status == 1 ? 2 : 1
          })

          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.storeInfoManage.getStoreList(params)
        if (res.storesQueryList && res.storesQueryList.list) {
          this.tableData = res.storesQueryList.list.map(item => {
            const payWayIds = (item.payWayIds || []).map(Number)

            item.payWayIds = payWayIds

            let payWayStr = ''

            if (payWayIds.length == 2) {
              payWayStr = '聚合支付、充值/赊账'
            } else {
              payWayStr = payWayIds.includes(1)
                ? '充值/赊账'
                : payWayIds.includes(2)
                ? '聚合支付'
                : ''
            }

            item.payWayStr = payWayStr

            return item
          })
        }
        this.totalCount = res.storesQueryList.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    }
  }
}
</script>

<style scoped lang="scss"></style>
