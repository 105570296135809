<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="`${currentItem.storeName || ''}余额操作记录`"
    :visible.sync="show"
    width="1200px"
  >
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="充值记录" name="first">
          <div class="sumWrap">
            <div class="item">
              <span class="label">可用余额：￥</span>
              <span class="value">{{
                $utils.formatNum(Number(currentAmount) + Number(leftAmount))
              }}</span>
            </div>
            <div class="item">
              <span class="label">现金余额：￥</span>
              <span class="value">{{ $utils.formatNum(currentAmount) }}</span>
            </div>
            <div class="item">
              <span class="label">剩余赊销额度：￥</span>
              <span class="value">{{ $utils.formatNum(leftAmount) }}</span>
            </div>
            <div class="item">
              <span class="label">已用额度：￥</span>
              <span class="value">{{ $utils.formatNum(usedAmount) }}</span>
            </div>
          </div>
          <el-table :data="tableData" v-loading="loading" :max-height="400">
            <el-table-column
              show-overflow-tooltip
              prop="operationType"
              label="操作类型"
            >
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="amount" label="金额" />
            <el-table-column
              show-overflow-tooltip
              prop="finalAmount"
              label="操作后余额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operator"
              label="操作员"
            />
            <el-table-column
              show-overflow-tooltip
              prop="approver"
              label="审核员"
            />

            <el-table-column
              show-overflow-tooltip
              prop="operationTime"
              label="操作时间"
            />
          </el-table>
          <div class="pagination-wrap">
            <el-pagination
              :total="totalCount"
              :page-size="params.limit"
              :current-page="params.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="额度变更记录" name="second">
          <div class="sumWrap">
            <div class="item">
              <span class="label">门店总额度：￥</span>
              <span class="value">{{ $utils.formatNum(totalAmount) }}</span>
            </div>
            <div class="item">
              <span class="label">剩余赊销额度：￥</span>
              <span class="value">{{ $utils.formatNum(leftAmount) }}</span>
            </div>
          </div>
          <el-table :data="tableData2" v-loading="loading" :max-height="400">
            <el-table-column
              show-overflow-tooltip
              prop="operationType"
              label="操作类型"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="modifyLimit"
              label="额度"
            />
            <el-table-column
              show-overflow-tooltip
              prop="finalLimit"
              label="门店额度"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operator"
              label="操作员"
            />
            <el-table-column
              show-overflow-tooltip
              prop="approver"
              label="审核员"
            />

            <el-table-column
              show-overflow-tooltip
              prop="operationTime"
              label="操作时间"
            />
          </el-table>
          <div class="pagination-wrap">
            <el-pagination
              :total="totalCount"
              :page-size="params.limit"
              :current-page="params.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="消费记录" name="third">
          <el-table :data="tableData3" v-loading="loading" :max-height="400">
            <el-table-column
              show-overflow-tooltip
              prop="tradeTime"
              label="交易时间"
            />
            <el-table-column
              show-overflow-tooltip
              prop="tradeType"
              label="交易类型"
            >
            </el-table-column>
            <el-table-column show-overflow-tooltip label="交易商品">
              <template slot-scope="{ row }">
                <div class="addressDetail">
                  <p
                    v-for="(item, index) in row.goodsNameList || []"
                    :key="index"
                  >
                    <span class="maxText">{{ item || '-' }}</span>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="tradePrice"
              label="交易金额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="surplusPrice"
              label="交易后余额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operator"
              label="操作员"
            />
            <el-table-column show-overflow-tooltip prop="remark" label="备注" />
          </el-table>
          <div class="pagination-wrap">
            <el-pagination
              :total="totalCount"
              :page-size="params.limit"
              :current-page="params.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      activeName: 'first',
      totalAmount: 0,
      currentAmount: 0,
      leftAmount: 0,
      usedAmount: 0,
      params: {
        limit: 20,
        page: 1
      },
      tableData: [],
      tableData2: [],
      tableData3: [],
      totalCount: 0,
      loading: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        let params = { ...this.params }
        let res = {}
        params.storesId = this.currentItem.id
        if (this.activeName == 'first') {
          params.type = 0
          res = await this.$api.storeInfoManage.getChargeRecord(params)
          this.tableData = res.page.list
        } else if (this.activeName == 'second') {
          params.type = 1
          res = await this.$api.storeInfoManage.getChargeRecord(params)
          this.tableData2 = res.page.list
        } else {
          params.storeId = this.currentItem.id
          res = await this.$api.storeInfoManage.getConsumeRecord(params)
          this.tableData3 = res.page.list
        }

        if (res.memberBalance) {
          const { balance, maxLimit } = res.memberBalance
          this.currentAmount = balance
          this.leftAmount = balance >= 0 ? maxLimit : maxLimit + balance
          this.usedAmount = balance >= 0 ? 0 : -balance
          this.totalAmount = maxLimit
        }

        this.totalCount = res.page.totalCount || 0
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    handleClick(tab) {
      this.params = {
        limit: 20,
        page: 1
      }

      this.getData()
    },
    sizeChange(size) {
      this.params.limit = size
      this.getData()
    },
    currentChange(page) {
      this.params.page = page
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.tipsHeader {
  font-weight: bold;
  margin: 10px 0;
}

.pagination-wrap {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.sumWrap {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  .item {
    margin-right: 50px;
  }
}
</style>
