<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="修改分组"
    :visible.sync="show"
    width="550px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :rules="rules"
        :model="form"
        size="mini"
        ref="ruleForm"
      >
        <p class="tipsHeader">
          已选中{{ multipleSelection.length }}个门店，请选择需要调整的分组
        </p>
        <el-form-item label="门店分组" prop="groupManageId">
          <el-select clearable v-model="form.groupManageId">
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  props: ['multipleSelection'],
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        groupManageId: [{ required: true, message: '请选择门店分组' }]
      },
      form: {
        groupManageId: ''
      },
      groupList: [],
      loading: false
    }
  },
  created() {
    this.getGroupList()
    this.form.ids = this.multipleSelection
  },
  methods: {
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            await this.$api.storeInfoManage.group(this.form)
            this.$message.success('保存成功')
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tipsHeader {
  margin: 15px 30px;
  color: #8e9095;
  font-weight: bold;
}
</style>
