<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="limitType == 1 ? '申请额度' : '申请临时额度'"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <div class="titleWrap">
        <span class="title">当前门店额度：{{ currentAmount || 0 }}</span>
        <span class="title">剩余可用额度：{{ leftAmount || 0 }}</span>
      </div>
      <el-form
        label-width="100px"
        :rules="rules"
        :model="form"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="新申请额度" prop="amount">
          <el-input
            style="width: 220px"
            v-model="form.amount"
            type="number"
            @input="
              val => {
                form.amount = keepTwoNum(val)
              }
            "
            placeholder="请输入新申请额度"
          ></el-input>
        </el-form-item>

        <el-form-item v-if="limitType == 2" label="到期时间" prop="date">
          <el-date-picker v-model="form.date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <p v-if="limitType == 2" class="tips">
          临时额度将在原有的额度进行增加，时间到期后临时额度将会失效
        </p>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  props: ['limitType'],
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        amount: [
          {
            required: true,
            message: '请输入新申请额度',
            trigger: 'blur'
          }
        ],
        date: [
          {
            required: true,
            message: '请选择到期时间',
            trigger: 'change'
          }
        ]
      },
      currentAmount: 0,
      leftAmount: 0,
      form: {
        amount: '',
        date: ''
      },
      btnLoading: false
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const res = await this.$api.storeInfoManage.queryBalance({
          storesId: this.currentItem.id
        })

        if (res.memberBalance) {
          this.currentAmount = res.memberBalance.maxLimit

          if (Number(res.memberBalance.balance) < 0) {
            this.leftAmount =
              Number(res.memberBalance.balance) +
              Number(res.memberBalance.maxLimit)
          } else {
            this.leftAmount = res.memberBalance.maxLimit
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true
          try {
            const params = {
              storesId: this.currentItem.id
            }

            if (this.limitType == 1) {
              params.maxLimit = +this.form.amount
              params.type = 3
            } else {
              params.type = 4
              params.tempLimit = +this.form.amount
              params.tempLimitTime = this.$utils.dateFormat(
                this.form.date,
                'YYYY-MM-DD HH:mm:ss'
              )
            }

            await this.$api.storeInfoManage.applyLimit(params)
            this.$message({
              type: 'success',
              message: '额度申请已提交！请联系审核员进行审核',
              duration: 3000
            })
            this.show = false
            this.$emit('getStatus')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.titleWrap {
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin-bottom: 18px;
  .title {
    font-weight: bold;
  }
}

.tips {
  color: #999;
}
</style>
