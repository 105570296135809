<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="线下充值申请"
    :visible.sync="show"
    width="550px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :rules="rules"
        :model="form"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="门店名称">
          <span>{{ currentItem.storesName || '-' }}</span>
        </el-form-item>
        <el-form-item label="现金余额">
          <span>￥{{ currentAmount || 0 }}</span>
        </el-form-item>
        <el-form-item label="操作类型" prop="amount">
          <el-select style="width: auto" v-model="form.type">
            <el-option
              v-for="item in operateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 6px">￥</span>
          <el-input
            style="width: 150px"
            maxlength="8"
            @input="
              val => {
                form.amount = onlyNum(val)
              }
            "
            v-model="form.amount"
            :placeholder="`请输入${form.type == 1 ? '充值' : '扣款'}金额`"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        amount: [
          {
            required: false,
            message: '请输入金额',
            trigger: 'change'
          }
        ]
      },
      operateList: [
        {
          label: '充值',
          value: 0
        },
        {
          label: '扣款',
          value: 1
        }
      ],
      currentAmount: 0,
      form: {
        type: 0,
        amount: '',
        remark: ''
      },
      loading: false
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const res = await this.$api.storeInfoManage.queryBalance({
          storesId: this.currentItem.id
        })

        if (res.memberBalance) {
          this.currentAmount = res.memberBalance.balance
        }
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$prompt('请输入您的登录密码确认为您本人操作', '提示', {
            confirmButtonText: '确定',
            closeOnClickModal: false,
            inputPattern: /\S+/,
            inputType: 'password',
            inputErrorMessage: '请输入登录密码',
            cancelButtonText: '取消'
          })
            .then(({ value }) => {
              this.confirmHandler(value)
            })
            .catch(() => {})
        }
      })
    },
    async confirmHandler(value) {
      this.loading = true
      try {
        const params = { ...this.form }
        params.storesId = this.currentItem.id
        params.password = value
        await this.$api.storeInfoManage.applyLimit(params)
        this.$message({
          type: 'success',
          message: '充值申请已提交！请联系审核员进行审核',
          duration: 4000
        })
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
