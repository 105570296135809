<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="`${currentItem.storeName || ''}赊销操作记录`"
    :visible.sync="show"
    width="1200px"
  >
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="还款记录" name="first">
          <p class="tipsHeader">当前赊销额度：{{ currentAmount || 0 }}</p>
          <el-table :data="tableData" v-loading="loading" :max-height="400">
            <el-table-column
              show-overflow-tooltip
              prop="operationType"
              label="操作类型"
            >
              <template slot-scope="{ row }">
                <span>{{
                  row.operationType == 1
                    ? '自动充值'
                    : row.operationType == 2
                    ? '线下充值'
                    : '余额扣减'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="amount"
              label="还款金额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="afterAmount"
              label="还款后剩余额度"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operator"
              label="操作员"
            />
            <el-table-column
              show-overflow-tooltip
              prop="auditor"
              label="审核员"
            />

            <el-table-column
              show-overflow-tooltip
              prop="operateTime"
              label="操作时间"
            />
          </el-table>
          <div class="pagination-wrap">
            <el-pagination
              :total="totalCount"
              :page-size="params.limit"
              :current-page="params.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="消费记录" name="second">
          <el-table :data="tableData" v-loading="loading" :max-height="400">
            <el-table-column
              show-overflow-tooltip
              prop="tradeTime"
              label="交易时间"
            />
            <el-table-column
              show-overflow-tooltip
              prop="tradeType"
              label="交易类型"
            >
              <template slot-scope="{ row }">
                <span>{{ row.tradeType == 1 ? '消费' : '退款' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="tradeGoods"
              label="交易商品"
            />
            <el-table-column
              show-overflow-tooltip
              prop="tradeAmount"
              label="交易金额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="afterTradeAmount"
              label="交易后赊销额度"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operator"
              label="操作员"
            />
            <el-table-column show-overflow-tooltip prop="remark" label="备注" />
          </el-table>
          <div class="pagination-wrap">
            <el-pagination
              :total="totalCount"
              :page-size="params.limit"
              :current-page="params.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      activeName: 'first',
      currentAmount: '0',
      params: {
        limit: 20,
        page: 1
      },
      tableData: [],
      totalCount: 0,
      loading: false
    }
  },
  created() {
    // this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        let params = { ...this.params }

        const res =
          this.activeName == 'first'
            ? await this.$api.storeInfoManage.getChargeRecord(params)
            : await this.$api.storeInfoManage.getConsumeRecord(params)

        if (res.page && res.page.list) {
          this.tableData = res.page.list
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    handleClick(tab) {
      this.getData()
    },
    sizeChange(size) {
      this.params.limit = size
      this.getData()
    },
    currentChange(page) {
      this.params.page = page
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.tipsHeader {
  font-weight: bold;
  margin: 10px 0;
}

.pagination-wrap {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
