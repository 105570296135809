<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    width="550px"
  >
    <div class="content" v-loading="loading">
      <el-form
        label-width="100px"
        :rules="rules"
        :model="form"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="付款方式">
          <el-checkbox-group v-model="form.payWayIds">
            <el-checkbox
              v-for="item in typeList"
              :key="item.label"
              :label="item.label"
            >
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-if="form.payWayIds.includes(1)" label="门店赊销额度">
          <span style="font-weight: bold; margin-right: 15px">{{
            amountLimit || 0
          }}</span>

          <el-button type="text" @click="limitHandler(1)"
            >申请修改额度</el-button
          >
          <el-button type="text" @click="limitHandler(2)"
            >申请临时额度</el-button
          >
        </el-form-item>
        <el-form-item label="门店分组">
          <el-select style="width: auto" clearable v-model="form.groupManageId">
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="submit"
        >确定</el-button
      >
    </span>

    <AmountChangeModal
      v-if="amountChangeVisible"
      @getStatus="getStatus"
      :visible.sync="amountChangeVisible"
      :limitType="limitType"
      :currentItem="currentItem"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import AmountChangeModal from './AmountChangeModal'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        groupManageId: [{ required: true, message: '请选择门店分组' }]
      },
      typeList: [
        {
          label: 1,
          title: '会员付款'
        },
        {
          label: 2,
          title: '线上付款'
        }
      ],
      amountLimit: 0,
      amountStatus: '未申请',
      form: {
        payWayIds: [],
        groupManageId: ''
      },
      limitType: 1,
      amountChangeVisible: false,
      groupList: [],
      loading: false,
      btnLoading: false
    }
  },
  components: {
    AmountChangeModal
  },
  created() {
    this.getGroupList()
    this.getStatus()
    // if (this.currentItem) {
    //   this.form.payWayIds = this.currentItem.payWayIds || []
    //   this.form.groupManageId = this.currentItem.groupManageId
    // }
  },
  methods: {
    limitHandler(type) {
      if (this.amountStatus == '已申请' && type == 2) {
        this.$message({
          type: 'warning',
          message: '已有新的申请在审核中，请等审核通过后再重新申请！',
          duration: 4000
        })

        return
      }

      this.amountChangeVisible = true
      this.limitType = type
    },
    async getStatus() {
      this.loading = true
      try {
        const res = await this.$api.storeInfoManage.getStatus({
          id: this.currentItem.id
        })

        if (res.memberBalance) {
          this.amountLimit = res.memberBalance.maxLimit
          this.amountStatus =
            res.memberBalance.tempLimitStatus == 1 ? '已申请' : '未申请'
        }

        if (res.stores) {
          this.form.payWayIds = res.stores.payWayIds.map(Number) || []
          this.form.groupManageId = res.stores.groupManageId
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }
            params.id = this.currentItem.id

            await this.$api.storeInfoManage.editInfo(params)
            this.$message.success('保存成功')
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
