<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="门店还款"
    :visible.sync="show"
    width="550px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :rules="rules"
        :model="form"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="门店名称">
          <span>{{ currentItem.storeName || '-' }}</span>
        </el-form-item>
        <el-form-item label="总额度">
          <span>￥{{ currentItem.totalAmount || 0 }}</span>
        </el-form-item>
        <el-form-item label="可用额度">
          <span>￥{{ currentItem.amount || 0 }}</span>
        </el-form-item>
        <el-form-item label="本次还款" prop="repayAmount">
          <el-input
            @input="inputHandler"
            v-model="form.repayAmount"
            placeholder="请输入本次还款金额，还款金额不可大于可用额度"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        repayAmount: [
          {
            required: true,
            message: '请输入还款金额',
            trigger: 'blur'
          }
        ]
      },
      form: {
        repayAmount: '',
        remark: ''
      },
      loading: false
    }
  },
  created() {},
  methods: {
    inputHandler(val) {
      if (val > Number(this.currentItem.amount)) {
        this.form.repayAmount = this.currentItem.amount
        return
      }
      this.form.repayAmount = onlyNum(val)
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$prompt('请输入您的登录密码确认为您本人操作', '提示', {
            confirmButtonText: '确定',
            closeOnClickModal: false,
            inputPattern: /\S+/,
            inputType: 'password',
            inputErrorMessage: '请输入登录密码',
            cancelButtonText: '取消'
          })
            .then(({ value }) => {
              this.confirmHandler(value)
            })
            .catch(() => {})
        }
      })
    },
    async confirmHandler(value) {
      this.loading = true
      try {
        const params = { ...this.form }
        params.id = this.currentItem.id
        await this.$api.storeInfoManage.repayment(params)
        this.$message({
          type: 'success',
          message: '还款申请已提交！请联系审核员进行审核',
          duration: 4000
        })
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
