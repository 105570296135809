<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="导入"
    :visible.sync="show"
    width="400px"
  >
    <div class="content">
      <div class="tip">
        请上传门店充值模板<el-button
          type="text"
          @click="getDownloadTemplateMchUrl"
          >（下载模板）</el-button
        >
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="submit"
        >确定</el-button
      >
    </span>

    <el-upload
      drag
      ref="upload"
      :on-change="onchange"
      :show-file-list="true"
      :multiple="false"
      :auto-upload="false"
      accept=".csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-zip,application/x-zip-compressed, application/vnd.ms-excel,.xls,.doc,.docx,application/msword,"
      name="file"
      action="null"
    >
      <div @click="clearHandler">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
    </el-upload>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      title="错误信息"
      :visible.sync="errorVisible"
      width="400px"
    >
      <div class="content" style="max-height: 300px; overflow: auto">
        <p v-for="item in failList" :key="item.index">
          <b>第{{ item.index }}行</b> {{ item.errorMsg }}
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="errorVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['params', 'multipleSelection'],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      failList: []
    }
  },
  methods: {
    onchange(file) {
      this.file = file.raw
    },

    clearHandler() {
      this.$refs.upload.clearFiles()
    },

    async submit() {
      this.$prompt('请输入您的登录密码进行验证', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputType: 'password',
        inputPattern: /\S+/,
        inputErrorMessage: '请输入登录密码'
      })
        .then(async ({ value }) => {
          try {
            this.loading = true
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('password', value)

            const res = await this.$api.storeInfoManage.batchImport(formData)

            if (!res.errorList) {
              this.$message.success('导入成功')
              this.show = false
            } else {
              this.$message.success('导入失败')
              this.errorVisible = true
              this.failList = res.errorList
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getDownloadTemplateMchUrl() {
      await this.$api.storeInfoManage.exportChargeTemplate({
        storesIds: this.multipleSelection,
        storesName: this.params.storesName || undefined,
        payWayId: this.params.payWayId || undefined,
        groupManageId: this.params.groupManageId || undefined
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
